<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="py-1 text-center">
          <img
            class="d-block mx-auto mb-4"
            src="@/assets/logo.png"
            alt="Logo XTC Indonesia"
            width="128"
            height="128"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-2 text-center">
          <p>
            <i class="bi bi-exclamation" style="font-size: 6rem; color: red"></i
            ><br />Kode Status: 403
          </p>
        </div>
        <div class="col-md-10">
          <h3>Akses ditolak</h3>
          <p>Anda tidak punya akses kesini.</p>
          <a class="btn btn-danger" href="/">Kembali</a>
        </div>
      </div>
    </div>

    <div id="footer" class="text-center">
      Hak Cipta &copy; 1982-2023 XTC Indonesia
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped></style>
